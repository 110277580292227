<template>
  <div class="pages">
    <div id="particles-js"></div>
    <div class="particles_cover"></div>

    <div class="page page1 hidden" id="page1">
      <div class="logo logo_big"></div>
      <div class="sub_title hidden" id="page1SubTitle">提供一站式行业营销解决方案</div>
      <div class="footer">
        <div class="f_row f_row1 hidden">灯塔财经信息技术有限公司</div>
        <div class="f_row f_row2 hidden">中国·武汉</div>
      </div>
    </div>

    <div class="page page2 hidden" id="page2">
      <div class="logo logo_right"></div>
      <div class="title hidden" id="page2Title">概览</div>
      <div class="sub_title hidden" id="page2SubTitle">
        弹指文，一款高效内容营销神器，搭建从爆文推广—内容分发—营销获客（渠道活码）—客户画像—舆情监控—数据分析全流程服务系统，打通线上和线下全通道，赋能企业高智高效的精准营销。
      </div>
      <div class="tree_chart" ref="refTwoflanksTree1Holder">
        <TwoflanksTree ref="refTwoflanksTree1" id="refTwoflanksTree1" />
      </div>
    </div>

    <div class="page page3 hidden" id="page3">
      <div class="row">
        <div class="logo"></div>
        <div class="title_solution hidden" id="page3Title">旅游行业解决方案</div>
      </div>
    </div>

    <div class="page page4 hidden" id="page4">
      <div class="top_row">
        <div class="logo"></div>
        <div class="title_solution">旅游行业解决方案</div>
      </div>

      <div class="content">
        <div class="chart_desc hidden" id="page4ChartDesc">
          随着人们生活水平的不断提高，人们对 旅游需求不断增加。随着我国疫情逐渐
          得到控制，国内旅游行业逐渐恢复。
        </div>
        <div class="chart_holder hidden" id="page4ChartHolder">
          <div class="chart_name hidden" id="page4ChartName">国内旅游总人数（亿人次）</div>
          <div class="bar_chart" id="barChart1" ref="refBarChartHolder">
            <bar-chart ref="refBarChart" id="refBarChart" customClass="c_bar" />
          </div>
        </div>
      </div>
    </div>

    <div class="page page5 hidden" id="page5">
      <div class="top_row hidden">
        <div class="logo"></div>
        <div class="title_solution">旅游行业解决方案</div>
      </div>

      <div class="content">
        <div class="title" id="page5Title">旅游行业痛点</div>
        <ul class="pain_list hidden" id="page5PainList">
          <li>游客画像<br />无法生成</li>
          <li>营销方式 <br />单一</li>
          <li>舆情不能 <br />实时监测</li>
          <li>推广成本高</li>
          <li>老客户流失</li>
        </ul>
      </div>
    </div>

    <div class="page page_cata hidden" id="pageCata">
      <div class="top_row hidden">
        <div class="logo"></div>
        <div class="title_solution">旅游行业解决方案</div>
      </div>
      <div class="content">
        <div class="title hidden" id="pageCataTitle">目录</div>
        <ul class="cata_list">
          <li>1. 爆文推广</li>
          <li>2. 内容分发</li>
          <li>3. 渠道活码</li>
        </ul>
        <ul class="cata_list">
          <li>4. 客户画像</li>
          <li>5. 舆情监控</li>
          <li>6. 数据分析</li>
        </ul>
      </div>
    </div>

    <div class="page page6 hidden" id="page6">
      <div class="top_row">
        <div class="logo"></div>
        <div class="title_solution">旅游行业解决方案</div>
      </div>

      <div class="content">
        <div class="title hidden" id="page6Title">
          弹指文，一款高效内容营销神器，搭建从爆文推广—内容分发—营销获客（渠道活码）—客户画像—舆情监控—数据分析全流程服务系统，打通线上和线下全通道，赋能企业高智高效的精准营销。
        </div>
        <div class="process_holder">
          <div class="process1 hidden" id="page6Process1">内容创作</div>
          <div class="line1 hidden" id="page6Line1"></div>
          <div class="process2 hidden" id="page6Process2">
            <div class="p_title p_title1">社交渠道</div>
            <div class="p_title p_title2">视<br />频<br />渠<br />道</div>
            <div class="p_title p_title3">搜<br />索<br />渠<br />道</div>
            <div class="p_title p_title4">新闻渠道</div>
            <div class="p_title_center">全景<br />流量池</div>
            <ul class="hidden">
              <li class="li1"></li>
              <li class="li2"></li>
              <li class="li3"></li>
              <li class="li4"></li>
              <li class="li5"></li>
              <li class="li6"></li>
              <li class="li7"></li>
              <li class="li8"></li>
              <li class="li9"></li>
              <li class="li10"></li>
              <li class="li11"></li>
              <li class="li12"></li>
              <li class="li13"></li>
              <li class="li14"></li>
              <li class="li15"></li>
              <li class="li16"></li>
              <li class="li17"></li>
              <li class="li18"></li>
              <li class="li19"></li>
              <li class="li20"></li>
              <li class="li21"></li>
              <li class="li22"></li>
              <li class="li23"></li>
              <li class="li24"></li>
              <li class="li25"></li>
              <li class="li26"></li>
            </ul>
          </div>
          <div class="line1 line2 hidden" id="page6Line2"></div>
          <div class="process3 hidden" id="page6Process3">私域运营</div>
        </div>
      </div>
    </div>

    <div class="page page7 hidden" id="page7">
      <div class="top_row">
        <div class="logo"></div>
        <div class="title_solution">旅游行业解决方案</div>
      </div>
      <div class="title_content">
        <div class="title hidden" id="page7Title">1.爆文推广</div>
        <div class="sub_title hidden" id="page7SubTitle">让每一次分享都成为您的获客商机</div>
      </div>
    </div>

    <div class="page page8 hidden" id="page8">
      <div class="top_row">
        <div class="logo"></div>
        <div class="title_solution">旅游行业解决方案</div>
      </div>
      <div class="content">
        <div class="circle1 hidden">
          <div class="title_center hidden">爆文<br />推广</div>
          <div class="txt txt1">今日<br />热文</div>
          <div class="txt txt2">嵌入<br />互动</div>
          <div class="txt txt3">数据<br />分析</div>
          <div class="txt txt4">精准<br />成交</div>
        </div>

        <div class="circle2_holder">
          <div class="circle2">
            <div class="title_center hidden">嵌入互动</div>
            <div class="txt txt1 hidden">商品</div>
            <div class="txt txt2 hidden">投票</div>
            <div class="txt txt3 hidden">报名</div>
            <div class="txt txt4 hidden">名片</div>
          </div>
        </div>
      </div>
    </div>

    <div class="page page9 hidden" id="page9">
      <div class="top_row">
        <div class="logo"></div>
        <div class="title_solution">旅游行业解决方案</div>
      </div>
      <div class="content">
        <div class="title" id="page9Title">
          今日热文：汇聚最新最热门旅游资讯内容，把握内容创作方向；资讯内容包括政策法规、新闻、游记、本地生活以及公众号等相关内容。 
        </div>
        <div class="tab_holder">
          <ul class="tab_ul">
            <li class="">行业热文</li>
            <li>微信公众号</li>
            <li>本地生活</li>
            <li>政策类</li>
            <li>游记</li>
          </ul>
          <div class="tab_images">
            <img :src="tabImg1" alt="" class="img1" />
            <img :src="tabImg2" alt="" class="img2" />
          </div>
        </div>
      </div>
    </div>

    <div class="page page10 hidden" id="page10">
      <div class="top_row">
        <div class="logo"></div>
        <div class="title_solution">旅游行业解决方案</div>
      </div>
      <div class="content">
        <div class="title" id="page10Title">实操演示</div>
        <div class="video_row">
          <div class="video">
            <video :src="video10" ref="refPage10Video" id="page10Video"></video>
          </div>
        </div>
      </div>
    </div>

    <div class="page page_distri1 hidden" id="pageDistri1">
      <div class="top_row">
        <div class="logo"></div>
        <div class="title_solution">旅游行业解决方案</div>
      </div>
      <div class="title_content">
        <div class="title" id="pageDistri1Title">2.内容分发</div>
        <div class="sub_title hidden" id="pageDistri1SubTitle">
          针对旅游景区行业多渠道自动发布，图文、音频、视频，全网触达用户
        </div>
        <ul class="distri_logo hidden">
          <li class="logo1"></li>
          <li class="logo2"></li>
          <li class="logo3"></li>
          <li class="logo4"></li>
          <li class="logo5"></li>
          <li class="logo6"></li>
          <li class="logo7"></li>
          <li class="logo8"></li>
          <li class="logo9"></li>
        </ul>
      </div>
    </div>

    <div class="page page_distri2 hidden" id="pageDistri2">
      <div class="top_row">
        <div class="logo"></div>
        <div class="title_solution">旅游行业解决方案</div>
      </div>
      <div class="content">
        <div class="title" id="pageDistri2Title">实操演示</div>
        <div class="video">
          <video :src="videoDistri2" ref="refPageDistri2Video" id="pageDistri2Video"></video>
        </div>
      </div>
    </div>

    <div class="page page11 hidden" id="page11">
      <div class="top_row">
        <div class="logo"></div>
        <div class="title_solution">旅游行业解决方案</div>
      </div>
      <div class="title_content">
        <div class="title" id="page11Title">3.营销获客</div>
        <div class="sub_title hidden" id="page11SubTitle">
          直击私域流量运营痛点，助力企业打造私域流量池
        </div>
      </div>
    </div>

    <div class="page page12 hidden" id="page12">
      <div class="top_row">
        <div class="logo"></div>
        <div class="title_solution">旅游行业解决方案</div>
      </div>
      <div class="content">
        <div class="title" id="page12Title">
          渠道活码：给不同的渠道配置专属渠道活码，引导客户加企业微信好友，打造私域流量池
        </div>
        <div class="video">
          <video :src="video12" ref="refPage12Video" id="page12Video"></video>
        </div>
      </div>
    </div>

    <div class="page page13 hidden" id="page13">
      <div class="top_row">
        <div class="logo"></div>
        <div class="title_solution">旅游行业解决方案</div>
      </div>
      <div class="title_content">
        <div class="title" id="page13Title">4.客户画像</div>
        <div class="sub_title hidden" id="page13SubTitle">帮助企业判断客户意向，精准运营客户</div>
      </div>
    </div>

    <div class="page page14 hidden" id="page14">
      <div class="top_row">
        <div class="logo"></div>
        <div class="title_solution">旅游行业解决方案</div>
      </div>
      <div class="tree_content">
        <div class="tree_chart" ref="refTwoflanksTree2Holder">
          <TwoflanksTree ref="refTwoflanksTree2" id="refTwoflanksTree2" />
        </div>
        <div class="title hidden" id="page14Title">
          客户画像：帮助企业快速完善地记录客户动态、客户标签和客户信息。助力精准营销
        </div>
      </div>
    </div>

    <div class="page page15 hidden" id="page15">
      <div class="top_row">
        <div class="logo"></div>
        <div class="title_solution">旅游行业解决方案</div>
      </div>
      <div class="content">
        <div class="title" id="page15Title">实操演示</div>
        <div class="video">
          <video :src="video15" ref="refPage15Video" id="page15Video"></video>
        </div>
      </div>
    </div>

    <div class="page page16 hidden" id="page16">
      <div class="top_row">
        <div class="logo"></div>
        <div class="title_solution">旅游行业解决方案</div>
      </div>
      <div class="title_content">
        <div class="title" id="page16Title">5.舆情监控</div>
        <div class="sub_title hidden" id="page16SubTitle">
          为企业提供舆情发现、舆情跟踪、舆情报告、舆情视频等服务
        </div>
      </div>
    </div>

    <div class="page page17 hidden" id="page17">
      <div class="top_row">
        <div class="logo"></div>
        <div class="title_solution">旅游行业解决方案</div>
      </div>
      <div class="tree_content">
        <div class="tree_chart" ref="refTwoflanksTree3Holder">
          <TwoflanksTree ref="refTwoflanksTree3" id="refTwoflanksTree3" />
        </div>
        <div class="video none">
          <video :src="video17" ref="refPage17Video" id="page17Video"></video>
        </div>
        <div class="title hidden" id="page17Title">
          舆情监控：为企业提供舆情发现、舆情跟踪、舆情报告、舆情视频等服务
        </div>
      </div>
    </div>

    <div class="page page18 hidden" id="page18">
      <div class="top_row">
        <div class="logo"></div>
        <div class="title_solution">旅游行业解决方案</div>
      </div>
      <div class="title_content">
        <div class="title" id="page18Title">数据分析</div>
        <div class="sub_title hidden" id="page18SubTitle">全平台、多维度统计分析</div>
      </div>
    </div>

    <div class="page page19 hidden" id="page19">
      <div class="top_row">
        <div class="logo"></div>
        <div class="title_solution">旅游行业解决方案</div>
      </div>

      <div class="swiper-container product_swiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide"><img :src="slide1" alt="" /></div>
          <div class="swiper-slide"><img :src="slide2" alt="" /></div>
          <div class="swiper-slide"><img :src="slide3" alt="" /></div>
        </div>
      </div>
    </div>

    <div class="page page20 hidden" id="page20">
      <div class="top_row">
        <div class="logo"></div>
        <div class="title_solution">旅游行业解决方案</div>
      </div>
      <div class="title_content">
        <div class="title" id="page20Title">谢谢您的观看</div>
        <div class="sub_title hidden" id="page20SubTitle">
          「弹指文」为您提供一站式行业营销解决方案
        </div>
      </div>
      <div class="sale_card hidden">
        <img class="s_img s_element" :src="saleImg" />
        <div class="s_txt">
          <div class="s_element">姓名：张荣</div>
          <div class="s_element">职位：销售经理</div>
          <div class="s_element">电话：15802711289</div>
        </div>
      </div>
    </div>

    <audio :src="currentSideVoice" class="side_voice" ref="refSideVoice"></audio>
  </div>
</template>
<script>
import { anyElement } from "@/components/anyElement.js";
import { sleep, setRem, randomNum } from "@/utils/utils.js";
import anime from "animejs/lib/anime.es.js";
import axios from "axios";
import $ from "jquery";
import FontFaceObserver from "fontfaceobserver";
import barChart from "@/components/barChart/index_v1.0.vue";
import TwoflanksTree from "@/components/customTree/twoflanks_v1.0.vue";

import "swiper/css/swiper.min.css";
import Swiper from "swiper";

export default {
  data: function () {
    return {
      barData: [
        {
          name: "2017",
          value: 50.01,
        },
        {
          name: "2018",
          value: 55.39,
        },
        {
          name: "2019",
          value: 60.06,
        },
        {
          name: "2020",
          value: 28.79,
        },
        {
          name: "2021",
          value: 32.46,
        },
      ],
      flanksData1: {
        l: {
          name: "",
          children: [
            {
              name: "<h3>爆文推广</h3><ul><li>舆情监控</li><li>营销日历</li><li>今日热文</li><li>数图中心</li><li>内容管理</li><li>推广设置</li><li>我的推广</li><li>消息管理</li></ul>",
            },
            {
              name: "<h3>粉丝互动</h3><ul><li>评论管理</li><li>我的粉丝</li><li>我的关注</li><li>私信管理</li></ul>",
            },
            {
              name: "<h3>营销获客</h3><ul><li>渠道活码</li><li>欢迎语</li><li>话术库</li><li>营销素材</li></ul>",
            },
          ],
        },
        r: {
          name: "",
          children: [
            {
              name: "<h3>客户管理</h3><ul><li>客户画像</li><li>客户群列表</li><li>订单中心</li></ul>",
            },
            {
              name: "<h3>企业风控</h3><ul><li>违规提醒</li><li>在职继承</li><li>离职继承</li><li>聊天存档</li></ul>",
            },
            {
              name: "<h3>我的企业</h3><ul><li>成员管理</li><li>账号信息</li><li>权限管理</li><li>订单管理</li></ul>",
            },
          ],
        },
      },
      flankImg1: require("@/index/views/projects/tanzhiwIntroProject/images/flank1.png"),
      flanksData2: {
        l: {
          name: "",
          children: [
            { name: "<h3>聊天记录</h3>" },
            { name: "<h3>所在群聊</h3>" },
            { name: "<h3>用户信息</h3>" },
            { name: "<h3>互动轨迹</h3>" },
          ],
        },
        r: {
          name: "",
          children: [
            {
              name: "<h3>精准营销</h3><ul><li>分析用户行为，洞察客户意向</li><li>优化销售模式，缩短消费路径</li></ul>",
            },
          ],
        },
      },
      flankImg2: require("@/index/views/projects/tanzhiwIntroProject/images/flank2.png"),
      flanksData3: {
        l: {
          name: "",
          children: [
            { name: "<h3>舆情发现</h3>" },
            { name: "<h3>舆情跟踪</h3>" },
            { name: "<h3>舆情报告</h3>" },
            { name: "<h3>舆情视频</h3>" },
          ],
        },
        r: {
          name: "",
          children: [
            {
              name: "<h3>全天候  全平台  秒级</h3><ul><li>覆盖新闻、微博、短视频、博客等网络媒体</li></ul>",
            },
          ],
        },
      },
      flankImg3: require("@/index/views/projects/tanzhiwIntroProject/images/flank3.png"),
      saleImg: require("@/index/views/projects/tanzhiwIntroProject/images/sale_code_img.png"),
      slide1: require("@/index/views/projects/tanzhiwIntroProject/images/slide1.png"),
      slide2: require("@/index/views/projects/tanzhiwIntroProject/images/slide2.png"),
      slide3: require("@/index/views/projects/tanzhiwIntroProject/images/slide3.png"),
      video10: require("@/index/views/projects/tanzhiwIntroProject/videos/baowen.mp4"),
      video12: require("@/index/views/projects/tanzhiwIntroProject/videos/huoma.mp4"),
      video15: require("@/index/views/projects/tanzhiwIntroProject/videos/huaxiang.mp4"),
      video17: require("@/index/views/projects/tanzhiwIntroProject/videos/video4.mp4"),
      videoDistri2: require("@/index/views/projects/tanzhiwIntroProject/videos/fenfa.mp4"),
      currentSideVoice: "",
      sideoVoice1: require("@/index/views/projects/tanzhiwIntroProject/sideVoice/1.mp3"),
      sideoVoice2: require("@/index/views/projects/tanzhiwIntroProject/sideVoice/2.mp3"),
      sideoVoice3: require("@/index/views/projects/tanzhiwIntroProject/sideVoice/3.mp3"),
      sideoVoice4: require("@/index/views/projects/tanzhiwIntroProject/sideVoice/4.mp3"),
      sideoVoice5: require("@/index/views/projects/tanzhiwIntroProject/sideVoice/5.mp3"),
      sideoVoice6: require("@/index/views/projects/tanzhiwIntroProject/sideVoice/6.mp3"),
      sideoVoice7: require("@/index/views/projects/tanzhiwIntroProject/sideVoice/7.mp3"),
      sideoVoice8: require("@/index/views/projects/tanzhiwIntroProject/sideVoice/8.mp3"),
      sideoVoice9: require("@/index/views/projects/tanzhiwIntroProject/sideVoice/9.mp3"),
      sideoVoice10: require("@/index/views/projects/tanzhiwIntroProject/sideVoice/10.mp3"),
      sideoVoice11: require("@/index/views/projects/tanzhiwIntroProject/sideVoice/11.mp3"),
      sideoVoice12: require("@/index/views/projects/tanzhiwIntroProject/sideVoice/12.mp3"),
      sideoVoice13: require("@/index/views/projects/tanzhiwIntroProject/sideVoice/13.mp3"),
      sideoVoice14: require("@/index/views/projects/tanzhiwIntroProject/sideVoice/14.mp3"),
      sideoVoice15: require("@/index/views/projects/tanzhiwIntroProject/sideVoice/15.mp3"),
      tabImg1: "",
      tabImg2: "",
      tab1_1: require("@/index/views/projects/tanzhiwIntroProject/images/tab1-1@2x.png"),
      tab1_2: require("@/index/views/projects/tanzhiwIntroProject/images/tab1-2@2x.png"),
      tab2_1: require("@/index/views/projects/tanzhiwIntroProject/images/tab2-1@2x.png"),
      tab2_2: require("@/index/views/projects/tanzhiwIntroProject/images/tab2-2@2x.png"),
      tab3_1: require("@/index/views/projects/tanzhiwIntroProject/images/tab3-1@2x.png"),
      tab3_2: require("@/index/views/projects/tanzhiwIntroProject/images/tab3-2@2x.png"),
    };
  },
  components: { barChart, TwoflanksTree },
  methods: {
    playSideVoice(id) {
      this.currentSideVoice = this["sideoVoice" + id];
      this.$nextTick(() => {
        this.$refs.refSideVoice.play();
      });
    },
    async page1In() {
      this.playSideVoice(1);
      $("#page1").removeClass("hidden");
      this.page1Logo = anyElement({
        targets: document.querySelector("#page1 .logo"),
        isSplitTxt: false,
        duration: 1500,
        delay: 60,
        easing: "easeOutQuint",
        inEffect: "bottomIn",
        outEffect: "fadeOut",
      });
      this.page1Logo.runIn();

      await sleep(800);

      this.page1SubTitle = anyElement({
        targets: document.querySelector("#page1SubTitle"),
        isSplitTxt: true,
        duration: 700,
        delay: 40,
        easing: "easeOutQuint",
        inEffect: "perspectiveRightIn3",
        outEffect: "fadeOut",
      });
      this.page1SubTitle.runIn();

      await sleep(800);
      $("#page1 .f_row").removeClass("hidden");
      this.page1Footer = anyElement({
        targets: document.querySelectorAll("#page1 .f_row"),
        isSplitTxt: false,
        duration: 1000,
        delay: 500,
        easing: "easeOutQuint",
        inEffect: "topIn",
        outEffect: "bottomOut",
      });
      this.page1Footer.runIn();

      await sleep(300);
      this.page1Out();
    },
    async page1Out() {
      this.page1Logo.runOut();
      this.page1SubTitle.runOut();
      this.page1Footer.runOut();
      await sleep(100);
      this.page2In();
    },

    async page3In() {
      // this.playSideVoice(3);
      $("#page3").removeClass("hidden");
      this.page3Logo = anyElement({
        targets: document.querySelector("#page3 .logo"),
        isSplitTxt: false,
        duration: 1300,
        delay: 30,
        easing: "easeOutQuint",
        inEffect: "leftIn",
        outEffect: "leftOut",
      });
      this.page3Logo.runIn();

      await sleep(100);

      this.page3Title = anyElement({
        targets: document.querySelector("#page3Title"),
        isSplitTxt: true,
        duration: 1300,
        delay: 30,
        easing: "easeOutQuint",
        inEffect: "rightIn",
        outEffect: "rightOut",
      });
      this.page3Title.runIn();

      await sleep(1.7*1000);
      this.page3Out();
    },
    async page3Out() {
      this.page3Logo.runOut();
      this.page3Title.runOut();
      await sleep(200);
      $("#page3Title").addClass("hidden");

      await sleep(300);
      this.page6In();
    },
    async page2In() {
      $("#page2").removeClass("hidden");
      this.page2Logo = anyElement({
        targets: document.querySelector("#page2 .logo"),
        isSplitTxt: false,
        duration: 1500,
        delay: 60,
        easing: "easeOutQuint",
        inEffect: "rightIn",
        outEffect: "fadeOut",
      });
      this.page2Logo.runIn();
      await sleep(800);

      this.page2Title = anyElement({
        targets: document.querySelector("#page2Title"),
        isSplitTxt: true,
        duration: 1500,
        delay: 60,
        easing: "easeOutQuint",
        inEffect: "perspectiveRightIn3",
        outEffect: "fadeOut",
      });
      this.page2Title.runIn();
      await sleep(800);

      this.playSideVoice(1);

      this.page2SubTitle = anyElement({
        targets: document.querySelector("#page2SubTitle"),
        isSplitTxt: true,
        duration: 500,
        delay: 50,
        easing: "easeOutQuint",
        inEffect: "typeIn",
        outEffect: "fadeOut",
      });
      this.page2SubTitle.runIn();

      await sleep(3000);

      const flankWidth = this.$refs.refTwoflanksTree1Holder.clientWidth;
      this.$refs.refTwoflanksTree1.init(this.flanksData1, {
        width: flankWidth,
        height: flankWidth * 0.31,
        lineLength: 180,
        nodeSpaceBetween: 180,
        multiple: 2,
        branchNodeWidth: 480, // 分支宽度
        branchNodeHeight: 160, // 分支高度
        rootNodeLength: 360,
        img: this.flankImg1,
      });

      await sleep(11 * 1000);
      this.page2Out();
    },
    async page2Out() {
      this.page2Logo.runOut();
      this.page2Title.runOut();
      this.page2SubTitle.runOut();
      $("#page2 .tree_chart").addClass("hidden");
      await sleep(400);
      this.page3In();
    },
    async page4In() {
      $("#page4").removeClass("hidden");
      this.page4TopRow = anyElement({
        targets: document.querySelector("#page4 .top_row"),
        isSplitTxt: false,
        duration: 1000 * 1,
        delay: 80,
        easing: "easeOutExpo",
        inEffect: "fadeIn",
        outEffect: "fadeOut",
      });
      this.page4TopRow.runIn();

      await sleep(800);

      this.page4ChartDesc = anyElement({
        targets: document.querySelector("#page4ChartDesc"),
        isSplitTxt: true,
        duration: 600,
        delay: 60,
        easing: "easeOutExpo",
        inEffect: "typeIn",
        outEffect: "fadeOut",
      });
      this.page4ChartDesc.runIn();

      this.playSideVoice(4);

      await sleep(300);

      this.page4ChartHolder = anyElement({
        targets: document.querySelector("#page4ChartHolder"),
        isSplitTxt: false,
        duration: 1000 * 1,
        delay: 80,
        easing: "easeOutExpo",
        inEffect: "fadeIn",
        outEffect: "fadeOut",
      });
      this.page4ChartHolder.runIn();
      await sleep(800);
      this.page4ChartName = anyElement({
        targets: document.querySelector("#page4ChartName"),
        isSplitTxt: true,
        duration: 1000 * 1,
        delay: 80,
        easing: "easeOutExpo",
        inEffect: "perspectiveRightIn1",
        outEffect: "fadeOut",
      });
      this.page4ChartName.runIn();

      await sleep(800);

      this.$refs.refBarChart.BarChart(this.barData, {
        x: (d) => d.name,
        y: (d) => d.value,
        width: this.$refs.refBarChartHolder.clientWidth,
        height: this.$refs.refBarChartHolder.clientWidth * 0.55,
        xPadding: 0.5,
        marginLeft: 60,
        duration: 2000,
        delay: 200,
        ease: "easeBack",
        // xTickNumber: 10,
        yTickNumber: 6,
        isNegativeSameDirection: true, //当数据中存在负值时，是否往同一方向画
      });

      await sleep(7000);
      this.page4Out();
    },
    async page4Out() {
      this.page4ChartDesc.runOut();
      this.page4ChartHolder.runOut();
      this.page4ChartName.runOut();
      this.$refs.refBarChart.playOut();
      await sleep(800);
      this.page5In();
    },
    async page5In() {
      $("#page5").removeClass("hidden");
      this.page5Title = anyElement({
        targets: document.querySelector("#page5Title"),
        isSplitTxt: true,
        duration: 1000 * 1,
        delay: 80,
        easing: "easeOutExpo",
        inEffect: "perspectiveRightIn1",
        outEffect: "fadeOut",
      });
      this.page5Title.runIn();

      await sleep(300);

      this.playSideVoice(5);

      $("#page5PainList").removeClass("hidden");
      this.page5PainList = anyElement({
        targets: document.querySelectorAll("#page5PainList li"),
        isSplitTxt: false,
        duration: 1800,
        delay: 400,
        easing: "easeOutBack",
        inEffect: "topIn",
        outEffect: "topOut",
      });
      this.page5PainList.runIn();

      await sleep(5000);
      this.page5Out();
    },
    async page5Out() {
      this.page5Title.runOut();
      this.page5PainList.runOut();
      await sleep(400);
      this.page6In();
    },
    async page6In() {
      $("#page6").removeClass("hidden");
      this.page6TopRow = anyElement({
        targets: document.querySelector("#page6 .top_row"),
        isSplitTxt: false,
        duration: 1000 * 1,
        delay: 80,
        easing: "easeOutExpo",
        inEffect: "fadeIn",
        outEffect: "fadeOut",
      });
      this.page6TopRow.runIn();

      await sleep(800);
      this.page6Title = anyElement({
        targets: document.querySelector("#page6Title"),
        isSplitTxt: true,
        duration: 300,
        delay: 30,
        easing: "easeOutExpo",
        inEffect: "typeIn",
        outEffect: "fadeOut",
      });
      this.page6Title.runIn();

      this.playSideVoice(1);

      await sleep(800);

      this.page6Process1 = anyElement({
        targets: document.querySelector("#page6Process1"),
        isSplitTxt: false,
        duration: 1400,
        delay: 80,
        easing: "easeOutExpo",
        inEffect: "fadeIn",
        outEffect: "fadeOut",
      });
      this.page6Process1.runIn();

      await sleep(800);

      this.page6Line1 = anyElement({
        targets: document.querySelector("#page6Line1"),
        isSplitTxt: false,
        duration: 1000,
        delay: 80,
        easing: "easeOutExpo",
        inEffect: "fadeIn",
        outEffect: "fadeOut",
      });
      this.page6Line1.runIn();

      await sleep(600);

      $("#page6Process2").removeClass("hidden");
      anime({
        targets: document.querySelector("#page6Process2"),
        duration: 1400,
        delay: 80,
        easing: "easeOutBack",
        opacity: [0, 1],
      });

      await sleep(600);
      $("#page6Process2 ul").removeClass("hidden");
      this.page6Li = anyElement({
        targets: document.querySelectorAll("#page6Process2 li"),
        isSplitTxt: false,
        duration: 2000,
        delay: 200,
        easing: "easeOutBack",
        inEffect: "revealIn",
        outEffect: "fadeOut",
      });
      this.page6Li.runIn();

      await sleep(1900);

      $("#page6Line2").removeClass("hidden");
      anime({
        targets: document.querySelector("#page6Line2"),
        duration: 1400,
        delay: 80,
        easing: "easeOutExpo",
        opacity: [0, 1],
      });

      await sleep(1200);

      $("#page6Process3").removeClass("hidden");
      anime({
        targets: document.querySelector("#page6Process3"),
        duration: 1400,
        delay: 80,
        easing: "easeOutExpo",
        opacity: [0, 1],
      });

      await sleep(8300);
      this.page6Out();
    },
    async page6Out() {
      this.page6Title.runOut();
      anime({
        targets: document.querySelectorAll(".process_holder > div"),
        duration: 1000,
        delay: 100,
        easing: "easeOutExpo",
        opacity: [1, 0],
      });

      await sleep(600);
      this.pageCataIn();
    },
    async pageCataIn() {
      $("#pageCata").removeClass("hidden");
      this.pageCataTitle = anyElement({
        targets: document.querySelector("#pageCataTitle"),
        isSplitTxt: true,
        duration: 900,
        delay: 80,
        easing: "easeOutExpo",
        inEffect: "perspectiveRightIn3",
        outEffect: "topOut",
      });
      this.pageCataTitle.runIn();
      await sleep(1000);
      this.pageCataLi = anyElement({
        targets: document.querySelectorAll(".cata_list li"),
        isSplitTxt: false,
        duration: 900,
        delay: 150,
        easing: "easeOutExpo",
        inEffect: "perspectiveRightIn3",
        outEffect: "topOut",
      });
      this.pageCataLi.runIn();
      await sleep(2000);
      this.pageCataOut();
    },
    async pageCataOut() {
      this.pageCataTitle.runOut();
      this.pageCataLi.runOut();
      await sleep(1200);
      this.page7In();
    },
    async page7In() {
      $("#page7").removeClass("hidden");
      this.page7Title = anyElement({
        targets: document.querySelector("#page7Title"),
        isSplitTxt: true,
        duration: 900,
        delay: 50,
        easing: "easeOutExpo",
        inEffect: "perspectiveRightIn3",
        outEffect: "topOut",
      });
      this.page7Title.runIn();

      this.playSideVoice(3);

      await sleep(600);

      this.page7SubTitle = anyElement({
        targets: document.querySelector("#page7SubTitle"),
        isSplitTxt: true,
        duration: 800,
        delay: 30,
        easing: "easeOutExpo",
        inEffect: "rotateIn3",
        outEffect: "bottomOut",
      });
      this.page7SubTitle.runIn();

      await sleep(2800);
      this.page7Out();
    },
    async page7Out() {
      this.page7Title.runOut();
      this.page7SubTitle.runOut();
      await sleep(1200);
      this.page8In();
    },
    async page8In() {
      this.playSideVoice(4);
      $("#page8").removeClass("hidden");
      this.page8Circle1 = anyElement({
        targets: document.querySelector("#page8 .circle1"),
        isSplitTxt: false,
        duration: 1300,
        delay: 50,
        easing: "easeOutExpo",
        inEffect: "fadeIn",
        outEffect: "leftOut",
      });
      this.page8Circle1.runIn();
      await sleep(300);
      this.page8Circle1Center = anyElement({
        targets: document.querySelector("#page8 .circle1 .title_center"),
        isSplitTxt: false,
        duration: 1400,
        delay: 50,
        easing: "easeOutBack",
        inEffect: "perspectiveScaleIn1",
        outEffect: "fadeOut",
      });
      this.page8Circle1Center.runIn();
      await sleep(300);
      $("#page8 .circle1 .txt").eq(0).addClass("on");
      await sleep(500);
      $("#page8 .circle1 .txt").eq(1).addClass("on");
      await sleep(500);
      $("#page8 .circle1 .txt").eq(2).addClass("on");
      await sleep(500);
      $("#page8 .circle1 .txt").eq(3).addClass("on");
      await sleep(1000);

      $("#page8 .circle2_holder").addClass("on");
      await sleep(800);

      this.page8Circle2Center = anyElement({
        targets: document.querySelector("#page8 .circle2 .title_center"),
        isSplitTxt: false,
        duration: 1000,
        delay: 100,
        easing: "easeOutBack",
        inEffect: "perspectiveScaleIn1",
        outEffect: "fadeOut",
      });
      this.page8Circle2Center.runIn();

      await sleep(3200);

      $("#page8 .circle2 .txt").removeClass("hidden");
      this.page8Circle2Txt = anyElement({
        targets: document.querySelectorAll("#page8 .circle2 .txt"),
        isSplitTxt: false,
        duration: 1300,
        delay: 500,
        easing: "easeOutBack",
        inEffect: "topIn",
        outEffect: "fadeOut",
      });
      this.page8Circle2Txt.runIn();

      await sleep(4000);
      this.page8Out();
    },
    async page8Out() {
      anime({
        targets: document.querySelectorAll("#page8 .circle1,#page8 .circle2"),
        duration: 1000,
        delay: 100,
        easing: "easeOutExpo",
        opacity: [1, 0],
        scale: [1, 0],
      });
      await sleep(300);
      this.page9In();
    },
    async page9In() {
      this.playSideVoice(5);
      $("#page9").removeClass("hidden");
      this.page9Title = anyElement({
        targets: document.querySelector("#page9Title"),
        isSplitTxt: true,
        duration: 300,
        delay: 30,
        easing: "easeOutExpo",
        inEffect: "typeIn",
        outEffect: "fadeOut",
      });
      this.page9Title.runIn();

      await sleep(1500);
      $("#page9 .tab_holder").addClass("on");
      this.tabImg1 = this.tab1_1;
      this.tabImg2 = this.tab1_2;

      await sleep(500);
      $("#page9 .tab_ul").addClass("on");
      await sleep(500);
      $("#page9 .tab_ul li").eq(0).addClass("on");
      await sleep(500);
      $("#page9 .tab_images img").eq(0).addClass("on");
      await sleep(500);
      $("#page9 .tab_images img").eq(1).addClass("on");
      await sleep(1500);
      $("#page9 .tab_ul li").removeClass("on");
      $("#page9 .tab_images img").removeClass("on");
      $("#page9 .tab_ul li").eq(1).addClass("on");
      await sleep(500);
      this.tabImg1 = this.tab2_1;
      this.tabImg2 = this.tab2_2;
      $("#page9 .tab_images img").eq(0).addClass("on");
      await sleep(500);
      $("#page9 .tab_images img").eq(1).addClass("on");
      await sleep(1500);
      $("#page9 .tab_ul li").removeClass("on");
      $("#page9 .tab_images img").removeClass("on");
      $("#page9 .tab_ul li").eq(2).addClass("on");
      await sleep(500);
      this.tabImg1 = this.tab3_1;
      this.tabImg2 = this.tab3_2;
      $("#page9 .tab_images img").eq(0).addClass("on");
      await sleep(500);
      $("#page9 .tab_images img").eq(1).addClass("on");

      await sleep(1500);
      this.page9Out();
    },
    async page9Out() {
      this.page9Title.runOut();
      $("#page9 .tab_holder").removeClass("on");
      await sleep(300);
      this.page10In();
    },
    async page10In() {
      $("#page10").removeClass("hidden");
      this.page10Title = anyElement({
        targets: document.querySelector("#page10Title"),
        isSplitTxt: true,
        duration: 1000,
        delay: 200,
        easing: "easeOutExpo",
        inEffect: "perspectiveScaleIn1",
        outEffect: "fadeOut",
      });
      this.page10Title.runIn();

      await sleep(1100);

      $("#page10 .video").addClass("on");
      await sleep(600);
      $("#page10 .video").addClass("fullscreen");
      await sleep(900);
      this.$refs.refPage10Video.play();
      await this.videoEnd("page10Video");

      await sleep(300);
      this.page10Out();
    },
    videoEnd(videoId) {
      return new Promise((resolve, reject) => {
        document.getElementById(videoId).addEventListener(
          "ended",
          () => {
            resolve();
          },
          false
        );
      });
    },
    async page10Out() {
      $("#page10 .video").removeClass("fullscreen");
      await sleep(900);
      $("#page10 .video").removeClass("on");
      this.page10Title.runOut();
      await sleep(700);
      this.pageDistri1In();
    },
    async pageDistri1In() {
      $("#pageDistri1").removeClass("hidden");
      this.pageDistri1Title = anyElement({
        targets: document.querySelector("#pageDistri1Title"),
        isSplitTxt: true,
        duration: 1000,
        delay: 200,
        easing: "easeOutExpo",
        inEffect: "perspectiveScaleIn1",
        outEffect: "fadeOut",
      });
      this.pageDistri1Title.runIn();

      this.playSideVoice(6);

      await sleep(400);

      this.pageDistri1SubTitle = anyElement({
        targets: document.querySelector("#pageDistri1SubTitle"),
        isSplitTxt: true,
        duration: 200,
        delay: 20,
        easing: "easeOutBack",
        inEffect: "obliqueIn",
        outEffect: "fadeOut",
      });
      this.pageDistri1SubTitle.runIn();

      await sleep(1400);

      $("#pageDistri1 .distri_logo").removeClass("hidden");
      this.pageDistri1Logos = anyElement({
        targets: document.querySelectorAll("#pageDistri1 .distri_logo li"),
        isSplitTxt: false,
        duration: 500,
        delay: 80,
        easing: "easeOutBack",
        inEffect: "perspectiveScaleIn1",
        outEffect: "bottomOut",
      });
      this.pageDistri1Logos.runIn();

      await sleep(3600);
      this.pageDistri1Out();
    },
    async pageDistri1Out() {
      this.pageDistri1Title.runOut();
      this.pageDistri1SubTitle.runOut();
      this.pageDistri1Logos.runOut();
      await sleep(500);
      this.pageDistri2In();
    },
    async pageDistri2In() {
      $("#pageDistri2").removeClass("hidden");
      this.pageDistri2Title = anyElement({
        targets: document.querySelector("#pageDistri2Title"),
        isSplitTxt: true,
        duration: 500,
        delay: 50,
        easing: "easeOutCubic",
        inEffect: "typeIn",
        outEffect: "fadeOut",
      });
      this.pageDistri2Title.runIn();

      await sleep(500);

      $("#pageDistri2 .video").addClass("on");
      await sleep(600);
      $("#pageDistri2 .video").addClass("fullscreen");
      await sleep(800);
      this.$refs.refPageDistri2Video.play();
      await this.videoEnd("pageDistri2Video");

      await sleep(800);
      this.pageDistri2Out();
    },
    async pageDistri2Out() {
      $("#pageDistri2 .video").removeClass("fullscreen");
      await sleep(700);
      $("#pageDistri2 .video").removeClass("on");
      this.pageDistri2Title.runOut();
      await sleep(1600);
      this.page11In();
    },
    async page11In() {
      $("#page11").removeClass("hidden");
      this.page11Title = anyElement({
        targets: document.querySelector("#page11Title"),
        isSplitTxt: true,
        duration: 1000,
        delay: 200,
        easing: "easeOutExpo",
        inEffect: "perspectiveScaleIn1",
        outEffect: "fadeOut",
      });
      this.page11Title.runIn();

      this.playSideVoice(7);

      await sleep(1800);

      this.page11SubTitle = anyElement({
        targets: document.querySelector("#page11SubTitle"),
        isSplitTxt: true,
        duration: 500,
        delay: 50,
        easing: "easeOutBack",
        inEffect: "obliqueIn",
        outEffect: "fadeOut",
      });
      this.page11SubTitle.runIn();

      await sleep(3800);
      this.page11Out();
    },
    async page11Out() {
      this.page11Title.runOut();
      this.page11SubTitle.runOut();
      await sleep(500);
      this.page12In();
    },
    async page12In() {
      $("#page12").removeClass("hidden");
      this.page12Title = anyElement({
        targets: document.querySelector("#page12Title"),
        isSplitTxt: true,
        duration: 500,
        delay: 50,
        easing: "easeOutCubic",
        inEffect: "typeIn",
        outEffect: "fadeOut",
      });
      this.page12Title.runIn();

      this.playSideVoice(8);

      await sleep(2600);

      $("#page12 .video").addClass("on");
      await sleep(600);
      $("#page12 .video").addClass("fullscreen");
      await sleep(800);
      this.$refs.refPage12Video.play();
      await this.videoEnd("page12Video");

      await sleep(1800);
      this.page12Out();
    },
    async page12Out() {
      $("#page12 .video").removeClass("fullscreen");
      await sleep(700);
      $("#page12 .video").removeClass("on");
      this.page12Title.runOut();
      await sleep(1600);
      this.page13In();
    },
    async page13In() {
      $("#page13").removeClass("hidden");
      this.page13Title = anyElement({
        targets: document.querySelector("#page13Title"),
        isSplitTxt: true,
        duration: 700,
        delay: 100,
        easing: "easeOutCubic",
        inEffect: "obliqueIn",
        outEffect: "leftOut",
      });
      this.page13Title.runIn();

      this.playSideVoice(9);

      await sleep(400);

      this.page13SubTitle = anyElement({
        targets: document.querySelector("#page13SubTitle"),
        isSplitTxt: true,
        duration: 500,
        delay: 50,
        easing: "easeOutBack",
        inEffect: "obliqueIn",
        outEffect: "rightOut",
      });
      this.page13SubTitle.runIn();

      await sleep(2400);
      this.page13Out();
    },
    async page13Out() {
      this.page13Title.runOut();
      this.page13SubTitle.runOut();
      await sleep(700);
      this.page14In();
    },
    async page14In() {
      $("#page14").removeClass("hidden");

      const flankWidth = this.$refs.refTwoflanksTree2Holder.clientWidth;
      this.$refs.refTwoflanksTree2.init(this.flanksData2, {
        width: flankWidth,
        height: flankWidth * 0.41,
        lineLength: 60,
        nodeSpaceBetween: 180,
        multiple: 2,
        branchNodeWidth: 420, // 分支宽度
        branchNodeHeight: 150, // 分支高度
        rootNodeLength: 280,
        img: this.flankImg2,
      });

      await sleep(1400);
      this.playSideVoice(10);

      this.page14Title = anyElement({
        targets: document.querySelector("#page14Title"),
        isSplitTxt: true,
        duration: 700,
        delay: 20,
        easing: "easeOutBack",
        inEffect: "obliqueIn",
        outEffect: "rightOut",
      });
      this.page14Title.runIn();

      await sleep(6500);
      this.page14Out();
    },
    async page14Out() {
      $("#page14 .tree_chart").addClass("hidden");
      this.page14Title.runOut();
      await sleep(600);
      this.page15In();
    },
    async page15In() {
      $("#page15").removeClass("hidden");
      this.page15Title = anyElement({
        targets: document.querySelector("#page15Title"),
        isSplitTxt: true,
        duration: 700,
        delay: 20,
        easing: "easeOutBack",
        inEffect: "obliqueIn",
        outEffect: "rightOut",
      });
      this.page15Title.runIn();

      await sleep(600);

      $("#page15 .video").addClass("on");
      await sleep(600);
      $("#page15 .video").addClass("fullscreen");
      await sleep(800);
      this.$refs.refPage15Video.play();
      await this.videoEnd("page15Video");
      await sleep(500);
      this.page15Out();
    },
    async page15Out() {
      $("#page15 .video").removeClass("fullscreen");
      await sleep(500);
      $("#page15 .video").removeClass("on");
      await sleep(300);
      this.page15Title.runOut();
      await sleep(300);
      this.page16In();
    },
    async page16In() {
      $("#page16").removeClass("hidden");
      this.page16Title = anyElement({
        targets: document.querySelector("#page16Title"),
        isSplitTxt: true,
        duration: 900,
        delay: 100,
        easing: "easeOutBack",
        inEffect: "obliqueIn",
        outEffect: "rightOut",
      });
      this.page16Title.runIn();
      this.playSideVoice(11);
      await sleep(600);
      this.page16SubTitle = anyElement({
        targets: document.querySelector("#page16SubTitle"),
        isSplitTxt: true,
        duration: 700,
        delay: 20,
        easing: "easeOutBack",
        inEffect: "topIn",
        outEffect: "rightOut",
      });
      this.page16SubTitle.runIn();
      await sleep(2200);
      this.page16Out();
    },
    async page16Out() {
      this.page16Title.runOut();
      this.page16SubTitle.runOut();
      await sleep(950);
      this.page17In();
    },
    async page17In() {
      $("#page17").removeClass("hidden");

      const flankWidth = this.$refs.refTwoflanksTree3Holder.clientWidth;
      this.$refs.refTwoflanksTree3.init(this.flanksData3, {
        width: flankWidth,
        height: flankWidth * 0.41,
        lineLength: 90,
        nodeSpaceBetween: 180,
        multiple: 2,
        branchNodeWidth: 420, // 分支宽度
        branchNodeHeight: 160, // 分支高度
        rootNodeLength: 280,
        img: this.flankImg3,
      });

      await sleep(1700);
      this.playSideVoice(12);

      this.page17Title = anyElement({
        targets: document.querySelector("#page17Title"),
        isSplitTxt: true,
        duration: 700,
        delay: 20,
        easing: "easeOutBack",
        inEffect: "obliqueIn",
        outEffect: "fadeOut",
      });
      this.page17Title.runIn();

      await sleep(4600);

      $("#page17 .tree_chart").addClass("hidden");
      await sleep(500);
      $("#page17 .tree_chart").addClass("none");
      $("#page17 .video").removeClass("none");
      $("#page17 .video").addClass("on");
      this.$refs.refPage17Video.play();
      this.page17Title.runOut();
      await sleep(500);
      $("#page17 .video").addClass("fullscreen");
      await this.videoEnd("page17Video");
      await sleep(500);

      this.page17Out();
    },
    async page17Out() {
      $("#page17 .video").removeClass("fullscreen");
      await sleep(600);
      $("#page17 .video").removeClass("on");
      await sleep(300);
      this.page18In();
    },
    async page18In() {
      $("#page18").removeClass("hidden");
      this.page18Title = anyElement({
        targets: document.querySelector("#page18Title"),
        isSplitTxt: true,
        duration: 900,
        delay: 100,
        easing: "easeOutBack",
        inEffect: "obliqueIn",
        outEffect: "rightOut",
      });
      this.page18Title.runIn();

      this.playSideVoice(13);

      await sleep(600);
      this.page18SubTitle = anyElement({
        targets: document.querySelector("#page18SubTitle"),
        isSplitTxt: true,
        duration: 700,
        delay: 20,
        easing: "easeOutBack",
        inEffect: "topIn",
        outEffect: "rightOut",
      });
      this.page18SubTitle.runIn();
      await sleep(2200);
      this.page18Out();
    },
    async page18Out() {
      this.page18Title.runOut();
      this.page18SubTitle.runOut();
      await sleep(300);
      this.page19In();
    },
    async page19In() {
      $("#page19").removeClass("hidden");
      this.initProductSwiper();
      $(".product_swiper").addClass("on");
      await sleep(1200);
      this.playSideVoice(14);
      await sleep(1200);
      this.productSwiper.slideNext(600);
      await sleep(2400);
      this.productSwiper.slideNext(600);
      await sleep(2400);
      this.productSwiper.slideNext(600);
      await sleep(5800);
      this.page19Out();
    },
    async page19Out() {
      $(".product_swiper").addClass("hidden");
      $(".top_row").addClass("hidden");
      await sleep(400);
      this.page20In();
    },
    async page20In() {
      $("#page20").removeClass("hidden");
      this.page20Title = anyElement({
        targets: document.querySelector("#page20Title"),
        isSplitTxt: true,
        duration: 900,
        delay: 100,
        easing: "easeOutBack",
        inEffect: "perspectiveRightIn3",
        outEffect: "rightOut",
      });
      this.page20Title.runIn();
      await sleep(600);
      this.playSideVoice(15);
      this.page20SubTitle = anyElement({
        targets: document.querySelector("#page20SubTitle"),
        isSplitTxt: true,
        duration: 700,
        delay: 20,
        easing: "easeOutBack",
        inEffect: "rotateIn3",
        outEffect: "rightOut",
      });
      this.page20SubTitle.runIn();

      await sleep(1000);

      $("#page20 .sale_card").removeClass("hidden");
      this.page20Card = anyElement({
        targets: document.querySelectorAll("#page20 .s_element"),
        isSplitTxt: false,
        duration: 1000,
        delay: 500,
        easing: "easeOutQuint",
        inEffect: "topIn",
        outEffect: "bottomOut",
      });
      this.page20Card.runIn();
    },
    async page20Out() {},

    initProductSwiper() {
      this.productSwiper = new Swiper(".product_swiper", {
        loop: true,
        lazyLoading: false,
        slidesPerView: 3,
        slidesPerGroup: 1,
        centeredSlides: true,
        on: {
          init() {},
          slideChangeTransitionStart() {},
        },
      });
    },
    async fontLoad() {
      const FZZhengHei = new FontFaceObserver("FZZhengHei");
      const harmonyRegular = new FontFaceObserver("harmonyRegular");
      const harmonyBold = new FontFaceObserver("harmonyBold");
      await Promise.all([
        FZZhengHei.load(null, 30 * 1000),
        harmonyRegular.load(null, 30 * 1000),
        harmonyBold.load(null, 30 * 1000),
      ]);
    },
    async initParticle() {
      let particleConfig = await axios.get(`/particlesConfigs/config_for_tanzhiw.json`);
      particlesJS("particles-js", particleConfig.data);
    },
  },
  async mounted() {
    //每个项目都可以独立设置
    setRem(1920);

    //初始化背景
    this.initParticle();

    //字体加载比较慢，等它加载完
    await this.fontLoad();

    //开始动画
    this.page3In();
    /*
      page3 解决方案
      page6 大转盘
      pageCata 目录
      page7 爆文推广 标题
      page8 爆文推广 详细
      page9 爆文推广 今日热文
      page10 爆文推广 实操
      pageDistri1 内容分发 标题
      pageDistri2 内容分发 实操
      page11 营销获客 标题
      page12 营销获客 实操
      page13 客户画像 标题
      page14 客户画像 图表
      page15 客户画像 实操
      page16 舆情监控 标题
      page17 舆情监控 图表和实操
      page18 数据分析 标题
      page19 数据分析 图片轮询
      page20 谢谢 
    */
  },
};
</script>
<style lang="less" scoped>
@import "index.less";
</style>
